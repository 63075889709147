import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'

import store from './store'
import router from './router'
import { auth, functions } from './firebase'

Vue.config.productionTip = false

loginNew.init(
    'vertretungsverwaltung',
    location.protocol + "//" + location.host + "/login",
    [],
    "signin:microsoft aid aid_secure profile usercentrics openid email"
);

loginNew.onAuthStateChanged(async () => {
    store.commit('setIsAuthenticating', true)
    if (loginNew.isSignedIn()) {
        const profile = loginNew.getProfile()
        if (profile.employee.secureLogin == true) {
            // Mitarbeiter hat sich mit einem Microsoft-Mitarbeiterkonto angemeldet
            if (auth.currentUser && auth.currentUser.uid == loginNew.getProfile().sub) {
                store.commit('setProfile', loginNew.getProfile())
                store.commit("setUserLogin", auth.currentUser.uid);
                store.commit('setIsAuthenticating', false)
            } else {
                const getAuthToken = functions.httpsCallable("getAuthToken");
                const response = await getAuthToken({ token: loginNew.getIdToken() });
                if (response.data.response === "OK") {
                    await auth.signInWithCustomToken(response.data.token);
                    store.commit('setProfile', loginNew.getProfile())
                    store.commit("setUserLogin", auth.currentUser.uid);
                    store.commit("setIsAuthenticating", false);
                } else {
                    router.push("/permission").catch(err => { })
                    store.commit('setIsAuthenticating', false)
                }
            }
        } else {
            // Mitarbeiter hat sich mit einem Mitarbeiter-E-Mail-Konto oder einem privatem Konto angemeldet
            store.commit("setError", { error: true, errorMessage: "Bitte melden Sie sich mit Ihrem Mitarbeiterkonto an." });
            store.commit('setIsAuthenticating', false)
        }
    } else {
        store.commit("setIsAuthenticating", false);
        if (auth.currentUser) {
            auth
                .signOut()
                .then(() => {
                    store.commit("setUserLogout");
                    console.log("signout successful")
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }
})

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Permission from '../views/Permission.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'Home', component: Home },
    { path: '/login', name: 'Login', component: Login },
    { path: '/permission', name: 'Permission', component: Permission }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router

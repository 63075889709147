<template>
  <v-data-table
    :items="
      $store.getters.isDefaultRepresentativeObjectActive
        ? computedAllAppsSelected
        : computedAllowedAppsTable
    "
    :headers="headers"
    :loading="$store.state.apps.isLoading || $store.state.users.isLoading"
    loading-text="Regeln werden geladen..."
    no-data-text="Kein Regeln vorhanden"
    disable-pagination
    disable-sort
    hide-default-footer
    class="elevation-5 my-table"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Vertretungsregeln</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
            Schließen
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-model="dialog" max-width="750px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Regel bearbeiten</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="editedItem.system"
                    label="System"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="editedItem.process"
                    label="Prozess"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    v-model="editedItem.isActive"
                    :label="
                      editedItem.appId == 'all'
                        ? 'Für alle Regeln aktivieren?'
                        : 'Regel aktivieren?'
                    "
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    label="Vertreter:in"
                    v-model="editedItem.representativeId"
                    :items="$store.getters.allUsers"
                    no-data-text="Keine Person gefunden"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-select
                    :items="timeRange"
                    label="Zeitspanne"
                    v-model="selectedTimeRange"
                  ></v-select>
                </v-col>

                <template v-if="selectedTimeRange == 'custom'">
                  <v-col cols="12" sm="6">
                    <DatePicker
                      :confirm="true"
                      :default-value="
                        editedItem.fromDate != ''
                          ? new Date(editedItem.fromDate)
                          : new Date()
                      "
                      :minute-step="30"
                      :disabled-date="notBeforeToday"
                      v-model="editedItem.fromDate"
                      type="datetime"
                      format="DD. MMM YYYY HH:mm"
                      placeholder="Von"
                    ></DatePicker>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <DatePicker
                      v-model="editedItem.toDate"
                      :confirm="true"
                      :disabled-date="validateToDate"
                      :minute-step="30"
                      type="datetime"
                      format="DD. MMM YYYY HH:mm"
                      placeholder="Bis"
                    ></DatePicker>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="fromDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.fromDate"
                          label="Von"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.fromDate"
                        @input="fromDateMenu = false"
                        :min="
                          new Date(Date.now()).toISOString().substring(0, 10)
                        "
                        locale="de-de"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="toDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.toDate"
                          label="Bis"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.toDate"
                        @input="toDateMenu = false"
                        :min="minDate"
                        locale="de-de"
                      ></v-date-picker>
                    </v-menu>
                  </v-col> -->
                </template>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Abbrechen </v-btn>
            <v-btn color="blue darken-1" text @click="save"> Speichern </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-slot:body="{ items, headers }">
      <tbody v-if="$vuetify.breakpoint.smAndUp">
        <tr v-for="(item, index) in items" :key="'item_' + index">
          <td
            class="d-sm-table-cell"
            :class="item.appId == 'all' ? 'default-representative-left' : ''"
          >
            {{ item.system }}
          </td>
          <td class="d-sm-table-cell">{{ item.process }}</td>
          <template v-if="item.direction == 'manuell'">
            <td
              class="d-sm-table-cell"
              :colspan="headers.length"
              v-html="item.advice"
            ></td>
          </template>
          <template v-else>
            <td class="d-sm-table-cell">
              {{ getUserName(item.representativeId) }}
            </td>
            <td class="d-sm-table-cell">
              {{ convertDate(item.fromDate) }}
            </td>
            <td class="d-sm-table-cell">
              {{ convertDate(item.toDate) }}
            </td>
            <td class="d-sm-table-cell">
              <v-simple-checkbox
                v-model="item.isActive"
                disabled
                class="activation-box"
                @click="activable(item) ? toggleIsActive(item) : ''"
              ></v-simple-checkbox>
            </td>
            <td
              class="d-sm-table-cell"
              :class="item.appId == 'all' ? 'default-representative-right' : ''"
            >
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
            </td>
          </template>
        </tr>
      </tbody>
      <tbody v-else>
        <tr
          v-for="(item, index) in items"
          :key="'item_' + index"
          class="v-data-table__mobile-table-row"
        >
          <td
            class="v-data-table__mobile-row"
            :class="item.appId == 'all' ? 'default-representative-mobile' : ''"
          >
            <div class="v-data-table__mobile-row__header">
              {{ headers[0]["text"] }}
            </div>
            <div class="v-data-table__mobile-row__cell">{{ item.system }}</div>
          </td>
          <td
            class="v-data-table__mobile-row"
            :class="item.appId == 'all' ? 'default-representative-mobile' : ''"
          >
            <div class="v-data-table__mobile-row__header">
              {{ headers[1]["text"] }}
            </div>
            <div class="v-data-table__mobile-row__cell">
              {{ item.process }}
            </div>
          </td>
          <template v-if="item.direction == 'manuell'">
            <td
              class="v-data-table__mobile-row"
              :class="
                item.appId == 'all' ? 'default-representative-mobile' : ''
              "
            >
              <div class="v-data-table__mobile-row__header">
                {{ headers[2]["text"] }}
              </div>
              <div
                class="v-data-table__mobile-row__cell"
                v-html="item.advice"
              ></div>
            </td>
          </template>
          <template v-else>
            <td
              class="v-data-table__mobile-row"
              :class="
                item.appId == 'all' ? 'default-representative-mobile' : ''
              "
            >
              <div class="v-data-table__mobile-row__header">
                {{ headers[2]["text"] }}
              </div>
              <div class="v-data-table__mobile-row__cell">
                {{ getUserName(item.representativeId) }}
              </div>
            </td>
            <td
              class="v-data-table__mobile-row"
              v-if="item.fromDate != ''"
              :class="
                item.appId == 'all' ? 'default-representative-mobile' : ''
              "
            >
              <div class="v-data-table__mobile-row__header">
                {{ headers[3]["text"] }}
              </div>
              <div class="v-data-table__mobile-row__cell">
                {{ convertDate(item.fromDate) }}
              </div>
            </td>
            <td
              class="v-data-table__mobile-row"
              v-if="item.toDate != ''"
              :class="
                item.appId == 'all' ? 'default-representative-mobile' : ''
              "
            >
              <div class="v-data-table__mobile-row__header">
                {{ headers[4]["text"] }}
              </div>
              <div class="v-data-table__mobile-row__cell">
                {{ convertDate(item.toDate) }}
              </div>
            </td>
            <td
              class="v-data-table__mobile-row"
              :class="
                item.appId == 'all' ? 'default-representative-mobile' : ''
              "
            >
              <div class="v-data-table__mobile-row__header">
                {{ headers[5]["text"] }}
              </div>
              <div class="v-data-table__mobile-row__cell">
                <v-simple-checkbox
                  v-model="item.isActive"
                  disabled
                  class="activation-box"
                  @click="activable(item) ? toggleIsActive(item) : ''"
                ></v-simple-checkbox>
              </div>
            </td>
            <td
              class="v-data-table__mobile-row"
              :class="
                item.appId == 'all' ? 'default-representative-mobile' : ''
              "
            >
              <div class="v-data-table__mobile-row__header">
                {{ headers[6]["text"] }}
              </div>
              <div class="v-data-table__mobile-row__cell">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
              </div>
            </td>
          </template>
        </tr>
      </tbody>
    </template>

    <!-- <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" v-if="item.notes">
        {{ item.notes }}
      </td>
    </template> -->
  </v-data-table>
</template>

<script>
import { firestore } from "../firebase";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/de";

export default {
  components: {
    DatePicker,
  },

  data() {
    return {
      timeRange: [
        { text: "Immer", value: "always" },
        { text: "1 Stunde", value: "1 hour" },
        { text: "4 Stunden", value: "4 hours" },
        { text: "Diese Woche", value: "this week" },
        { text: "Benutzerdefiniert", value: "custom" },
      ],
      selectedTimeRange: "",
      datetime: null,
      overlay: false,
      snackbar: false,
      snackbarText: "",
      snackbarColor: "success",
      dialog: false,
      // fromDateMenu: false,
      // toDateMenu: false,
      fromDate: "",
      toDate: "",
      headers: [
        { text: "System", value: "system" },
        { text: "Prozess", value: "process" },
        { text: "Vertreter:in", value: "representative" },
        { text: "Von", value: "fromDate" },
        { text: "Bis", value: "toDate" },
        { text: "Aktiv", value: "isActive" },
        { text: "Bearbeiten", value: "bearbeiten" },
      ],
      editedItem: {
        fromDate: "",
        toDate: "",
        appId: "",
        representativeId: "",
        isActive: false,
        isDefaultRepresentative: false,
      },
    };
  },

  computed: {
    computedAllowedAppsTable() {
      let apps = [...this.$store.getters.allowedAppsTable];

      const defaultObject = this.$store.getters.defaultRepresentativeObject;
      if (defaultObject) {
        apps.unshift({
          ...defaultObject,
          fromDate:
            defaultObject.fromDate != "" ? defaultObject.fromDate.toDate() : "",
          toDate:
            defaultObject.toDate != "" ? defaultObject.toDate.toDate() : "",
        });
      }
      return apps;
    },

    computedAllAppsSelected() {
      const defaultObject = this.$store.getters.defaultRepresentativeObject;
      if (defaultObject) {
        return this.computedAllowedAppsTable.map((app) => {
          return {
            ...app,
            representativeId: defaultObject.representativeId
              ? defaultObject.representativeId
              : "",
            fromDate:
              defaultObject.fromDate != ""
                ? defaultObject.fromDate.toDate()
                : "",
            toDate:
              defaultObject.toDate != "" ? defaultObject.toDate.toDate() : "",
            isActive: true,
          };
        });
      }
      return this.computedAllowedAppsTable;
    },
  },

  methods: {
    activable(item) {
      if (item.appId == "all") {
        return true;
      } else {
        if (this.$store.getters.defaultRepresentativeObject.isActive) {
          return false;
        }
        return true;
      }
    },

    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },

    validateToDate(date) {
      const today = new Date();
      if (this.editedItem.fromDate != "") {
        return date <= this.editedItem.fromDate;
      }
      return date < today;
    },

    convertDate(date) {
      if (date != "") {
        return new Date(date).toLocaleDateString("de-DE", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
      }
      return "";
    },

    getUserName(id) {
      if (id != "") {
        const user = this.$store.state.users.users.find(
          (user) => user.persid == id
        );
        return `${user.firstname} ${user.name} (${user.department})`;
      }
      return "";
    },

    close() {
      this.dialog = false;
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    toggleIsActive(item) {
      if (item.representativeId == "") {
        this.showSnackbar(
          "error",
          "Sie können keine Regel ohne Vertreter:in aktivieren."
        );
        return;
      }

      this.overlay = true;
      firestore
        .collection("representatives")
        .doc(item.representativeEntryId)
        .set({ isActive: !item.isActive }, { merge: true })
        .then(() => {
          this.snackbarColor = "success";
          this.snackbarText = "Änderungen werden gespeichert.";
          this.snackbar = true;
          this.overlay = false;
        })
        .catch((err) => {
          console.warn(err);
          this.showSnackbar(
            "error",
            "Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal."
          );
          this.overlay = false;
        });
    },

    addHours(hours) {
      const dateTime = new Date();
      dateTime.setTime(dateTime.getTime() + hours * 60 * 60 * 1000);
      return dateTime;
    },

    save() {
      let updatedObject = {
        appId: this.editedItem.appId,
        // fromDate: new Date(this.editedItem.fromDate),
        // toDate: new Date(this.editedItem.toDate),
        fromDate: "",
        toDate: "",
        representativeId: this.editedItem.representativeId,
        isActive: this.editedItem.isActive,
        uid: this.$store.state.user.userProperties.persid,
      };

      const currentTime = new Date();

      switch (this.selectedTimeRange) {
        case "always": {
          updatedObject.fromDate = "";
          updatedObject.toDate = "";
          break;
        }
        case "1 hour": {
          updatedObject.fromDate = currentTime;
          updatedObject.toDate = this.addHours(1);
          break;
        }
        case "4 hours": {
          updatedObject.fromDate = currentTime;
          updatedObject.toDate = this.addHours(4);
          break;
        }
        case "this week": {
          updatedObject.fromDate = currentTime;
          const newTime = new Date();
          newTime.setHours(0, 0, 0, 0);
          var lastday = newTime.getDate() - (newTime.getDay() - 1) + 5;
          updatedObject.toDate = new Date(newTime.setDate(lastday));
          break;
        }
        case "custom": {
          updatedObject.fromDate = new Date(this.editedItem.fromDate);
          updatedObject.toDate = new Date(this.editedItem.toDate);
          break;
        }
      }

      if (this.selectedTimeRange == "") {
        this.showSnackbar("error", "Wählen Sie bitte die Zeitspanne aus.");
        return;
      }

      if (
        this.selectedTimeRange == "custom" &&
        updatedObject.fromDate > updatedObject.toDate
      ) {
        this.showSnackbar("error", "Bitte überprüfen Sie das Datum erneut.");
        return;
      }

      if (this.editedItem.representativeId == "") {
        this.showSnackbar("error", "Wählen Sie bitte eine:n Vertreter:in aus.");
        return;
      }

      if (this.editedItem.representativeEntryId == "") {
        firestore
          .collection("representatives")
          .add(updatedObject)
          .then(() => {
            this.showSnackbar("success", "Änderungen werden gespeichert.");
            this.dialog = false;
          });
      } else {
        firestore
          .collection("representatives")
          .doc(this.editedItem.representativeEntryId)
          .set(updatedObject, { merge: true })
          .then(() => {
            this.showSnackbar("success", "Änderungen werden gespeichert.");
            this.dialog = false;
          });
      }

      this.selectedTimeRange = "";

      // if (
      //   this.editedItem.fromDate == "" ||
      //   this.editedItem.toDate == "" ||
      //   this.editedItem.representativeId == ""
      // ) {
      //   this.snackbarColor = "error";
      //   this.snackbarText =
      //     "Sie können keinen Eintrag ohne einen Vertreter und ein Datum speichern";
      //   this.snackbar = true;
      //   return;
      // }

      // if (updatedObject.fromDate > updatedObject.toDate) {
      //   this.snackbarColor = "error";
      //   this.snackbarText = "Bitte überprüfen Sie das Datum erneut.";
      //   this.snackbar = true;
      //   return;
      // }

      // if (this.editedItem.representativeEntryId == "") {
      //   firestore
      //     .collection("representatives")
      //     .add(updatedObject)
      //     .then(() => {
      //       this.snackbarColor = "success";
      //       this.snackbarText = "Änderungen werden gespeichert.";
      //       this.snackbar = true;
      //       this.dialog = false;
      //     });
      // } else {
      //   firestore
      //     .collection("representatives")
      //     .doc(this.editedItem.representativeEntryId)
      //     .set(updatedObject, { merge: true })
      //     .then(() => {
      //       this.snackbarColor = "success";
      //       this.snackbarText = "Änderungen werden gespeichert.";
      //       this.snackbar = true;
      //       this.dialog = false;
      //     });
      // }
    },

    showSnackbar(condition = "error", msg = "Standard-Fehlermeldung.") {
      this.snackbarText = msg;
      this.snackbarColor = condition;
      this.snackbar = true;
    },
  },
};
</script>

<style>
.default-representative-left {
  border-left: 5px solid #9b004b;
}

.default-representative-right {
  border-right: 5px solid #9b004b;
}

.default-representative-mobile {
  border-left: 5px solid #9b004b;
  border-right: 5px solid #9b004b;
}

.mx-datepicker {
  width: 100%;
}

.mx-calendar-content .cell.active {
  background-color: #9b004b;
}

.mx-time-column .mx-time-item.active {
  color: #9b004b;
}

.mx-table-date .today {
  color: #9b004b;
}
</style>

<template>
  <v-app>
    <v-app-bar app>
      <router-link to="/">
        <v-img
          alt="NEW Logo"
          class="shrink"
          contain
          src="@/assets/logo.svg"
          transition="scale-transition"
          width="100"
        />
      </router-link>

      <v-spacer></v-spacer>
      <v-toolbar-title :class="$vuetify.breakpoint.smAndUp ? 'pr-12' : ''">
        <div
          v-if="this.$vuetify.breakpoint.smAndUp"
          class="text-sm-h5 text-truncate"
        >
          Vertretungsverwaltung
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <div id="loginnew-dropdown"></div>
    </v-app-bar>

    <v-main v-if="!$store.state.auth.isAuthenticating">
      <v-overlay z-index="100" :value="$store.state.error.error">
        <v-card>
          <v-card-title>{{ $store.state.error.errorMessage }}</v-card-title>
          <v-card-actions class="justify-center">
            <v-btn class="white--text" color="#9b004b" @click="switchAccount">
              Konto Wechseln
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>
      <router-view></router-view>
    </v-main>
    <v-main v-else class="text-center pt-8">
      <v-container fill-height fluid style="height: 100%">
        <v-row align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="50"
          ></v-progress-circular>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  methods: {
    switchAccount() {
      this.$store.commit("setError", { error: false, errorMessage: "" });
      loginNew.auth();
    },
  },
};
</script>

<style>
#loginnew-dropdown .login-new-avatar span {
  display: none;
}

header.v-app-bar {
  contain: unset;
  transform: none !important;
  overflow: visible;
}
</style>

import { firestore } from '../../firebase'

const state = {
    users: [],
    firestoreListener: null,
    isLoading: false
}

const getters = {
    allUsers(state) {
        return state.users.map(user => {
            return {
                text: `${user.firstname} ${user.name} (${user.department})`,
                value: user.persid
            }
        }).sort((a, b) => {
            const nameA = a.text.toUpperCase();
            const nameB = b.text.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        })
    }
}

const actions = {
    attachAllUsersOnSnapshot({ state }, userId) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.users)
                return
            }

            state.isLoading = true
            state.firestoreListener = firestore
                .collection("users")
                .where('persid', '!=', userId)
                .onSnapshot(docs => {
                    const firebaseUsers = [];
                    docs.forEach((doc) => {
                        firebaseUsers.push({
                            ...doc.data(),
                            id: doc.id,
                        })
                    })
                    state.users = firebaseUsers
                    state.isLoading = false
                    resolve(state.users)
                }, (error) => {
                    console.warn(error.message)
                    reject(error)
                })
        })
    },

    detachAppsOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener()
            state.firestoreListener = null
        }
    }
}

export default { state, getters, actions }
